<template>
	<v-row>
		<v-dialog v-model="dialogErro.status" persistent>
			<CardDialog :dialog="dialogErro" />
		</v-dialog>
		<ProdutoDialog
			:dialog-produtos="dialogProdutos"
			:pedido-old="pedidoOld"
			:lista-produtos="listaProdutos"
			:carregando="carregando"
			@cancelar="dialogProdutos = false"
		></ProdutoDialog>
		<v-dialog v-model="dialogAgendamento" max-width="600" persistent scrollable>
			<v-card>
				<v-card-title>Agendamento de Entrega</v-card-title>
				<v-divider />
				<v-card-text class="px-4 pt-6">
					<v-text-field
						outlined
						dense
						type="datetime-local"
						label="Data de Entrega"
						v-model="pedidoNew.dtentrega"
					></v-text-field>
					<v-simple-table dense dark class="primary">
						<thead>
							<tr>
								<th>Produto</th>
								<th class="text-center text-truncate">Qtd Disponível</th>
								<th class="text-center text-truncate">Qtd a Entregar</th>
							</tr>
						</thead>
						<tbody v-if="pedidoNew.produtos.length > 0">
							<tr v-for="(u,i) in pedidoNew.produtos" :key="i">
								<td>{{u.idsubproduto}} - {{u.produto}}</td>
								<td class="text-center">{{u.quantidade}}</td>
								<td>
									<v-text-field
										dense
										type="number"
										suffix="M(s)"
										v-model.number="u.quantidadeagendar"
										class="mb-n3"
									></v-text-field>
								</td>
							</tr>
						</tbody>
					</v-simple-table>
				</v-card-text>
				<v-divider />
				<v-card-actions class="pa-3">
					<v-spacer />
					<v-btn
						:loading="carregando"
						text
						color="primary"
						elevation="0"
						@click="cancelaAgendamento()"
					>Cancelar</v-btn>
					<v-btn
						:disabled="validaAgenda()"
						:loading="carregando"
						color="primary"
						elevation="0"
						@click="confirmaAgendamento()"
					>Agendar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="dialogConfirmacao.status" max-width="500" persistent scrollable>
			<v-card>
				<v-card-title v-if="dialogConfirmacao.tipo == 1">Confirmar Entrega?</v-card-title>
				<v-card-title v-if="dialogConfirmacao.tipo == 2">Confirmar Cancelamento de Agendamento?</v-card-title>
				<v-card-title v-if="dialogConfirmacao.tipo == 3">Confirmar Cancelamento de Entrega?</v-card-title>
				<v-divider />
				<div class="text-center py-3">
					<v-icon size="100" color="red">mdi-help-circle-outline</v-icon>
				</div>
        <v-divider />
        <v-text-field
            v-model="motivo"
            label="Motivo"
            placeholder="Informe o motivo do cancelamento"
            :rules="caracteresRules"
            outlined
            dense
            autofocus
            class="mb-n4 pt-4 px-6"
            v-if="dialogConfirmacao.tipo != 1"
        >
        </v-text-field>
				<v-card-actions class="pa-3 px-6">
					<v-spacer />
					<v-btn
						:loading="carregando"
						text
						color="primary"
						elevation="0"
						@click="cancelaEntrega()"
					>Cancelar</v-btn>
					<BotaoConfirmacao
						:dialogConfirmacao="dialogConfirmacao"
            :motivo="motivo.length <= 10"
						:carregando="carregando"
						@action="acaoBotaoConfirmacao"
            class="ml-2"
					></BotaoConfirmacao>
				</v-card-actions>
			</v-card>
		</v-dialog>
    <!-- Filtros -->
		<v-col cols="12">
			<v-card>
				<v-card-title>
					<v-row no-gutters class="mb-n8">
						<v-col class="d-none d-md-block pr-1">
							<InputDatePicker
								:outlined="true"
								:dense="true"
								v-model="busca.dtinicio"
								label="Data inicial"
							/>
						</v-col>
						<v-col class="d-none d-md-block px-1">
							<InputDatePicker :outlined="true" :dense="true" v-model="busca.dtfim" label="Data final" />
						</v-col>
						<v-col class="d-none d-md-block px-1">
							<v-autocomplete
								:items="filiaisFiltro"
								clearable
								item-text="filial"
								item-value="idfilial"
								label="Filial"
								outlined
								dense
								:disabled="carregando"
								v-model="busca.idfilial"
							></v-autocomplete>
						</v-col>
						<v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
							<v-sheet class="text-center" height="100%">
								<v-row class="pa-3">
									<v-col cols="12" class="pr-3 pr-md-0 mb-n8">
										<v-autocomplete
											:items="filiaisFiltro"
											clearable
											item-text="filial"
											item-value="idfilial"
											label="Filial"
											outlined
											dense
											:disabled="carregando"
											v-model="busca.idfilial"
										></v-autocomplete>
									</v-col>
									<v-col cols="6">
										<InputDatePicker
											v-model="busca.dtinicio"
											label="Data inicial"
											:outlined="true"
											:dense="true"
										/>
									</v-col>
									<v-col cols="6">
										<InputDatePicker v-model="busca.dtfim" label="Data final" :outlined="true" :dense="true" />
									</v-col>
									<v-col cols="6">
										<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
									</v-col>
									<v-col cols="6">
										<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Filtrar</v-btn>
									</v-col>
								</v-row>
							</v-sheet>
						</v-bottom-sheet>
						<v-col class="pl-1">
							<v-text-field
								class="d-flex d-md-none"
								v-model="busca.busca"
								label="Buscar"
								outlined
								dense
								append-outer-icon="mdi-menu"
								@click:append-outer="sheet = !sheet"
							/>
							<v-text-field class="d-none d-md-block" v-model="busca.busca" label="Buscar" outlined dense />
						</v-col>
						<v-col cols="auto" class="ml-2">
							<v-btn
								:disabled="carregando"
								class="mt-1"
								color="primary"
								elevation="0"
								fab
								x-small
								@click="listar(0)"
							>
								<v-icon>mdi-magnify</v-icon>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-title>
			</v-card>
		</v-col>
    <!-- Colunas de Pendentes -->
		<v-col cols="12" md="4">
			<v-card>
				<v-card-title class="primary white--text">
					Pendentes
					<v-spacer />
					<v-icon color="white">mdi-account-clock-outline</v-icon>
				</v-card-title>
				<v-card-text class="pa-0" v-if="listaPendentes">
					<draggable class="list-group" v-model="listaPendentes" v-bind="dragOptionsP" :move="onMove">
						<transition-group id="pendentes" tag="ul">
							<li
								class="list-card-itens my-3 pa-3 rounded white--text red"
								v-for="l,i in listaPendentes"
								:key="i"
								@dblclick="abrirAgendamento(l, 'P')"
							>
                <strong class="mr-3">Empresa:</strong>
                <span class="text-truncate">{{l.idempresa}} - {{l.empresa}}</span>
                <br />
								<strong class="mr-3">Cliente:</strong>
								<span class="text-truncate">{{l.idclifor}} - {{l.cliente}}</span>
								<br />
								<strong class="mr-3">Pedido:</strong>
								{{l.idpedido}}
								<br />
								<strong class="mr-3">Quantidade:</strong>
								{{l.quantidade}} Metro(s)
								<v-btn class="ml-3" small icon color="white" elevation="0" @click="listarProdutos(l, 'P')">
									<v-icon class="font-weight-bold">mdi-eye-outline</v-icon>
								</v-btn>
								<br />
								<strong class="mr-3">Data prevista:</strong>
								{{l.dtprevisao}}
							</li>
						</transition-group>
					</draggable>
				</v-card-text>
			</v-card>
		</v-col>
    <!-- Colunas de Agendados -->
		<v-col cols="12" md="4">
			<v-card>
				<v-card-title class="primary white--text">
					Agendados
					<v-spacer />
					<v-icon color="white">mdi-clock-alert-outline</v-icon>
				</v-card-title>
				<v-card-text class="pa-0" v-if="listaAgendados">
					<draggable class="list-group" v-model="listaAgendados" v-bind="dragOptionsA" :move="onMove">
						<transition-group id="agendados" tag="ul">
							<li
								class="list-card-itens my-3 pa-3 rounded white--text orange"
								v-for="l,i in listaAgendados"
								:key="i"
								@dblclick="abrirEntrega(l)"
							>
								<v-btn
									class="float-end"
									x-small
									fab
									color="error"
									elevation="0"
									@click="dialogConfirmacao = {status:true, tipo:2}, pedidoOld= l, motivo = ''"
								>
									<v-icon class="font-weight-bold">mdi-close</v-icon>
								</v-btn>
                <strong class="mr-3">Empresa:</strong>
                <span class="text-truncate">{{l.idempresa}} - {{l.empresa}}</span>
                <br />
								<strong class="mr-3">Cliente:</strong>
								<span class="text-truncate">{{l.idclifor}} - {{l.cliente}}</span>
								<br />
								<strong class="mr-3">Pedido:</strong>
								{{l.hash}}
								<br />
								<strong class="mr-3">Quantidade:</strong>
								{{l.quantidade}} Metro(s)
								<v-btn class="ml-3" small icon color="white" elevation="0" @click="listarProdutos(l, 'A')">
									<v-icon class="font-weight-bold">mdi-eye-outline</v-icon>
								</v-btn>
								<br />
								<strong class="mr-3">Data agendada:</strong>
								{{l.dtprevisao}}
							</li>
						</transition-group>
					</draggable>
				</v-card-text>
			</v-card>
		</v-col>
    <!-- Colunas de Entregues -->
		<v-col cols="12" md="4">
			<v-card>
				<v-card-title class="primary white--text">
					Entregues
					<v-spacer />
					<v-icon color="white">mdi-clock-check-outline</v-icon>
				</v-card-title>
				<v-card-text class="pa-0" v-if="listaEntregues">
					<draggable class="list-group" v-model="listaEntregues" v-bind="dragOptionsE" :move="onMove">
						<transition-group id="entregues" tag="ul">
							<li
								class="list-card-itens my-3 pa-3 rounded white--text green"
								v-for="l,i in listaEntregues"
								:key="i"
							>
								<v-btn class="float-end" x-small fab color="error" elevation="0">
									<v-icon
										class="font-weight-bold"
										@click="dialogConfirmacao = {status:true, tipo:3}, pedidoOld= l"
									>mdi-close</v-icon>
								</v-btn>
                <strong class="mr-3">Empresa:</strong>
                <span class="text-truncate">{{l.idempresa}} - {{l.empresa}}</span>
                <br />
								<strong class="mr-3">Cliente:</strong>
								<span class="text-truncate">{{l.idclifor}} - {{l.cliente}}</span>
								<br />
								<strong class="mr-3">Pedido:</strong>
								{{l.hash}}
								<br />
								<strong class="mr-3">Quantidade:</strong>
								{{l.quantidade}} Metro(s)
								<v-btn class="ml-3" small icon color="white" elevation="0" @click="listarProdutos(l, 'E')">
									<v-icon class="font-weight-bold">mdi-eye-outline</v-icon>
								</v-btn>
								<br />
								<strong class="mr-3">Data entrega:</strong>
								{{l.dtprevisao}}
							</li>
						</transition-group>
					</draggable>
				</v-card-text>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
import draggable from "vuedraggable";
import { mapState } from "vuex";
import InputDatePicker from "../Widgets/InputDatePicker.vue";
import BotaoConfirmacao from "./Widgets/BotaoConfirmacao.vue";
import ProdutoDialog from "./Widgets/ProdutoDialog.vue";
import mixinFilial from "../../util/mixinFilial";
import axios from "axios";
import CardDialog from "../Widgets/CardDialog.vue";

export default {
	name: "hello",
	mixins: [mixinFilial],
	components: {
		draggable,
		InputDatePicker,
		CardDialog,
		BotaoConfirmacao,
		ProdutoDialog,
	},
	data: () => ({
    motivo: '',
		sheet: false,
		carregando: false,
		dialogAgendamento: false,
		dialogConfirmacao: { status: false, tipo: 0 },
		dialogProdutos: false,
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		listaPendentes: [],
		listaAgendados: [],
		listaEntregues: [],
		listaProdutos: [],
		editable: true,
		pedidoNew: {
			idpedido: 0,
			cliente: "",
			dtentrega: "",
			produtos: [],
		},
		pedidoOld: {},
		busca: {
			dtinicio: `${
				new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
					.toISOString()
					.split("T")[0]
			}`,
			dtfim: `${
				new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
					.toISOString()
					.split("T")[0]
			}`,
			idfilial: null,
		},
    tipo: '',
    caracteresRules: [
      value => !!value || 'Este campo é obrigatório.',
      value => value.length > 10 || 'O motivo deve ter mais que 10 caracteres'
    ]
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		filiaisFiltro() {
			const idsFiliaisExcluidas = [6, 12, 25, 29, 33, 34, 37];

			if (this.filiais.lista) {
				return this.filiais.lista.filter(
					(filial) => !idsFiliaisExcluidas.includes(filial.idfilial)
				);
			}

			return [];
		},
		dragOptionsP() {
			return {
				animation: 0,
				group: "description",
				disabled: !this.editable,
				ghostClass: "ghost",
			};
		},
		dragOptionsA() {
			return {
				animation: 0,
				group: "description",
				disabled: !this.editable,
				ghostClass: "ghost",
			};
		},
		dragOptionsE() {
			return {
				animation: 0,
				group: "description",
				disabled: !this.editable,
				ghostClass: "ghost",
			};
		},
	},
	methods: {
		// Lista informações de pedidos pendentes, agendados e entregues
		listar() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}concreto/pedido/agenda`, {
					busca: this.busca.busca || null,
					dtini: this.busca.dtinicio || null,
					dtfim: this.busca.dtfim || null,
					idfilial: this.busca.idfilial || null,
				})
				.then((res) => {
					this.carregando = false;
					this.listaPendentes = res.data.pendentes || [];
					this.listaAgendados = res.data.agendados || [];
					this.listaEntregues = res.data.entregues || [];
				})
				.catch(() => {
					this.dados = { total: 0 };
					this.carregando = false;
				});
		},
		// Função lista produtos de um pedido para visualização
		listarProdutos(pedido, tipo) {
			if (!pedido.idpedido) return;
			this.pedidoOld = pedido;

			this.carregando = true;
			return axios
				.post(`${this.backendUrl}concreto/pedido/agenda/produtos`, {
					idpedido: pedido.idpedido,
          hash: pedido.hash,
          tipo: tipo,
				})
				.then((res) => {
					this.carregando = false;
					this.listaProdutos = res.data;
					this.dialogProdutos = true;
				})
				.catch(() => {
					this.listaProdutos = [];
					this.carregando = false;
				});
		},

		// Função lista produtos de um pedido para agendamento
		listarProdutosNew(pedido, tipo) {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}concreto/pedido/agenda/produtos`, {
					idpedido: pedido.idpedido,
          hash:pedido.hash,
          tipo: tipo,
				})
				.then((res) => {
					this.carregando = false;
					this.pedidoNew.produtos = res.data;
					this.pedidoNew.produtos = this.pedidoNew.produtos.map(
						(v) => {
							return { ...v, quantidadeagendar: v.quantidade };
						}
					);
				})
				.catch(() => {
					this.pedidoNew.produtos = [];
					this.carregando = false;
				});
		},

		// Função fecha o dialog de agendamento sem concluir
		cancelaAgendamento() {
      this.listar(0);
			// this.listaPendentes.push(this.pedidoOld);
			// this.listaAgendados = this.listaAgendados.filter(
			// 	(v) => v.idpedido != this.pedidoOld.idpedido
			// );
			this.dialogAgendamento = false;
		},

		// Função realiza a requisição de agendamento de um pedido para entrega
		confirmaAgendamento() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}concreto/pedido/agendar`, {
					idpedido: this.pedidoOld.idpedido,
					idfilial: this.pedidoOld.idfilial,
					idclifor: this.pedidoOld.idclifor,
					dtentrega: this.pedidoNew.dtentrega,
					produtos: this.pedidoNew.produtos,
				})
				.then((res) => {
					this.carregando = false;
					this.dialogAgendamento = false;
					if (res.data) {
						this.listar();
					} else {
						this.cancelaAgendamento();
						this.dialogErro.title = "Erro";
						if (res.data.erro) {
							this.dialogErro.msg =
								"Não foi possível agendar a entrega!";
						} else {
							this.dialogErro.msg = res.data.erro;
						}
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch(() => {
					this.cancelaAgendamento();
					this.carregando = false;
					this.dialogAgendamento = false;
					this.dialogErro.title = "Erro";
					this.dialogErro.msg = "Não foi possível agendar a entrega!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},

		// Função abri o dialog de confirmação de entrega
    abrirAgendamento(pedido, tipo) {
      if (!pedido.idpedido) {
        return;
      }

      this.pedidoOld = pedido;
      this.carregando = true;
      this.listarProdutosNew(pedido, tipo);
      this.listaAgendados.push(this.pedidoOld);
      this.listaPendentes = this.listaPendentes.filter(
          (v) => v.idpedido !== this.pedidoOld.idpedido
      );
      this.dialogAgendamento = true;
    },
    // Função retorna o card para o status de pendente
		abrirEntrega(pedido) {
			this.pedidoOld = pedido;
			this.listaEntregues.push(this.pedidoOld);
			this.listaAgendados = this.listaAgendados.filter(
				(v) => v.idpedido !== this.pedidoOld.idpedido
			);
			this.dialogConfirmacao = { status: true, tipo: 1 };
		},

		// Função retorna o card para o status de agendando
		cancelaEntrega() {
      this.listar(0);
			// this.listaAgendados.push(this.pedidoOld);
			// this.listaEntregues = this.listaEntregues.filter(
			// 	(v) => v.idpedido != this.pedidoOld.idpedido
			// );
      this.dialogConfirmacao = { status: false, tipo: 0 };

		},
		// Função confirma a entrega de um pedido
		confirmaEntrega() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}concreto/pedido/entregar`, {
					idpedido: this.pedidoOld.idpedido,
					idfilial: this.pedidoOld.idfilial,
					idclifor: this.pedidoOld.idclifor,
          hash: this.pedidoOld.hash,
				})
				.then((res) => {
					this.carregando = false;
					this.dialogConfirmacao = { status: false, tipo: 0 };
					if (res.data) {
						this.listar();
					} else {
						this.cancelaEntrega();
						this.dialogErro.title = "Erro";
						if (res.data.erro) {
							this.dialogErro.msg =
								"Não foi possível confirmar a entrega do concreto!";
						} else {
							this.dialogErro.msg = res.data.erro;
						}
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch(() => {
					this.cancelaEntrega();
					this.carregando = false;
					this.dialogConfirmacao.status = false;
					this.dialogErro.title = "Erro";
					this.dialogErro.msg =
						"Não foi possível confirmar a entrega do concreto!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		// Função cancela o agendamento de um pedido
		cancelaPedidoAgendado() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}concreto/pedido/cancela/agendamento`, {
					idpedido: this.pedidoOld.idpedido,
          idfilial: this.pedidoOld.idempresa,
          hash: this.pedidoOld.hash,
          observacao: this.motivo,
				})
				.then((res) => {
					this.carregando = false;
					this.dialogConfirmacao.status = false;
					if (res.data) {
						this.listar();
					} else {
						this.dialogErro.title = "Erro";
						if (res.data.erro) {
							this.dialogErro.msg =
								"Não foi possível cancelar agendamento de entrega!";
						} else {
							this.dialogErro.msg = res.data.erro;
						}
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch(() => {
					this.carregando = false;
					this.dialogConfirmacao.status = false;
					this.dialogErro.title = "Erro";
					this.dialogErro.msg =
						"Não foi possível cancelar agendamento de entrega!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		// Função cancela a entrega de um pedido
		cancelaPedidoEntregue() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}concreto/pedido/cancela/entrega`, {
					idpedido: this.pedidoOld.idpedido,
          idfilial: this.pedidoOld.idempresa,
          hash: this.pedidoOld.hash,
          observacao: this.motivo,
				})
				.then((res) => {
					this.carregando = false;
					this.dialogConfirmacao.status = false;
					if (res.data) {
						this.listar();
					} else {
						this.dialogErro.title = "Erro";
						if (res.data.erro) {
							this.dialogErro.msg =
								"Não foi possível cancelar a entrega!";
						} else {
							this.dialogErro.msg = res.data.erro;
						}
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch(() => {
					this.carregando = false;
					this.dialogConfirmacao.status = false;
					this.dialogErro.title = "Erro";
					this.dialogErro.msg =
						"Não foi possível cancelar a entrega!";
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		// Função valida se as informações para agendamento do pedido estão corretas
		validaAgenda() {
			if (
				this.pedidoNew.produtos.length === 0 ||
				!this.pedidoNew.dtentrega
			) {
				return true;
			}

			const totalquantidadeagendar = this.pedidoNew.produtos.reduce(
				(total, produto) => total + produto.quantidadeagendar,
				0
			);

			const maior = this.pedidoNew.produtos.some(
				(v) => v.quantidadeagendar > v.quantidade
			);

			const menor = this.pedidoNew.produtos.some(
				(v) => v.quantidadeagendar < 0
			);

			if (totalquantidadeagendar === 0 || maior || menor) {
				return true;
			}

			return false;
		},
		// Função verifica qual função chamar para o dialog de confirmação
		acaoBotaoConfirmacao(action) {
      console.log(action);
			switch (action) {
				case "confirmaEntrega":
					this.confirmaEntrega();
					break;
				case "cancelaPedidoAgendado":
					this.cancelaPedidoAgendado();
					break;
				case "cancelaPedidoEntregue":
					this.cancelaPedidoEntregue();
					break;
				default:
					break;
			}
		},
		// Função de controle de movimento de cards
		onMove(e) {
			const { from, to } = e;
			switch (true) {
				case from.id === "pendentes" && to.id === "agendados":
					this.pedidoOld = e.draggedContext.element;
					this.listarProdutosNew(this.pedidoOld);
					this.dialogAgendamento = true;
					return true;

				case from.id === "agendados" && to.id === "entregues":
					this.pedidoOld = e.draggedContext.element;
					this.dialogConfirmacao = { status: true, tipo: 1 };
					return true;

				case from.id === "pendentes" && to.id === "entregues":
				case from.id === "agendados" && to.id === "pendentes":
				case from.id === "entregues" && to.id === "pendentes":
				case from.id === "entregues" && to.id === "agendados":
					return false;

				default:
					return false;
			}
		},
		async init() {
			await this.listar();
		},
	},
	watch: {},
	created() {
		this.init();
	},
};
</script>

<style>
.ghost {
	opacity: 0.8;
	background: #023449;
}

.v-application ul {
	padding: 0px;
}

.list-group {
	min-height: 20px;
	border: 2px solid #023449;
	padding-left: 10px;
	padding-right: 10px;
}

.list-card-itens {
	list-style-type: none;
}
</style>
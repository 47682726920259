<template>
	<div>
		<v-btn
			v-if="dialogConfirmacao.tipo === 1"
			:loading="carregando"
			color="primary"
			elevation="0"
			@click="emitAction('confirmaEntrega')"
		>Confirmar</v-btn>
		<v-btn
			v-if="dialogConfirmacao.tipo === 2"
      :disabled="motivo"
			:loading="carregando"
			color="primary"
			elevation="0"
			@click="emitAction('cancelaPedidoAgendado')"
		>Confirmar</v-btn>
		<v-btn
			v-if="dialogConfirmacao.tipo === 3"
      :disabled="motivo"
			:loading="carregando"
			color="primary"
			elevation="0"
			@click="emitAction('cancelaPedidoEntregue')"
		>Confirmar</v-btn>
	</div>
</template>

<script>
export default {
	props: {
    motivo: Boolean,
		dialogConfirmacao: Object,
		carregando: Boolean,
	},
	methods: {
		emitAction(action) {
			this.$emit("action", action);
		},
	},
};
</script>

<template>
	<v-dialog v-model="dialogProdutos" max-width="800" persistent>
		<v-card>
			<v-card-title class="primary white--text">Produtos</v-card-title>
			<v-divider />
			<v-card-text class="px-4 pt-6 text-body-1 primary--text">
				<strong>Pedido:</strong>
				{{ pedidoOld.hash }}
				<br />
				<strong>Cliente:</strong>
				{{ pedidoOld.idclifor }} - {{ pedidoOld.cliente }}
				<br />
				<v-divider class="my-3 primary" />
				<v-simple-table class="primary" dark>
					<thead>
						<tr>
							<th class="text-center">Cód</th>
							<th>Produto</th>
							<th class="text-center">Quantidade</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(u, i) in listaProdutos" :key="i">
							<td class="text-center">{{ u.idsubproduto }}</td>
							<td>{{ u.produto }}</td>
							<td class="text-center">{{ u.quantidade }}</td>
						</tr>
					</tbody>
				</v-simple-table>
			</v-card-text>
			<v-divider />
			<v-card-actions class="pa-3 primary white--text">
				<v-spacer />
				<v-btn
					:loading="carregando"
					text
					color="white"
					class="font-weight-bold"
					elevation="0"
					@click="cancelar"
				>Cancelar</v-btn>
				<v-spacer />
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
export default {
	props: {
		dialogProdutos: {
			type: Boolean,
			required: true,
		},
		pedidoOld: {
			type: Object,
			required: true,
		},
		listaProdutos: {
			type: Array,
			required: true,
		},
		carregando: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		cancelar() {
			this.$emit("cancelar");
		},
	},
};
</script>
